import type { SearchResponse } from '@vue-storefront/prestashop-api';
import { debounce } from 'lodash-es';
import { useSdk } from '~/sdk';

interface SearchState {
  query: string;
  data: SearchResponse['psdata'] | null;
  pending: boolean;
  error: string | null;
}

export const useSearch = () => {
  const { locale } = useI18n();
  const router = useRouter();
  const route = useRoute();

  const state = useState<SearchState>((): SearchState => ({
    query: '',
    data: { products: [], facets: {}, filters: [], pagination: {} },
    pending: false,
    error: null,
  }));

  async function search() {
    console.log('searching...');
    if (state.value.pending) return;
    state.value.pending = true;
    const route = useRoute();
    const { data, pending, error } = await useAsyncData(async () => {
      return await useSdk().commerce.search({
        resultsPerPage: 10,
        s: state.value.query,
        page: route.query.page ? parseInt(route.query.page as string) : 1,
      });
    });
    state.value.error = error.value?.message ?? null;
    if (data.value) {
      state.value.data = data.value;
    }
    state.value.pending = false;
  }

  watch(locale, search);

  watch(
    () => state.value.query,
    debounce(async () => {

      await router.replace({
        query: { ...route.query, page: 1 },
      });

      await search()
    }, 500),
  );

  return {
    ...toRefs(state.value),
    search
  };
};
